import React from 'react'
import { bool, func, number, shape } from 'prop-types'
import { graphql } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'

import Layout from './layout'
import SplitHero from './heroes/splitHero'
import ContentfulText from './contentfulText'
import LayoutContent from './layoutContent'
import CardGroup from './cards/cardGroup'
import TextContent from './textContent'
import Accordion from './accordion/accordion'
import AccordionButton from './accordion/accordionButton'
import Container from './container'
import { useBreakpoints } from '../utils/dom'
import { getHeroThemeBySlug, getHeroTheme } from '../utils/segments'
import { getContentfulLongTextAsNode, isString } from '../utils/'
import PatternImgUrl from '../images/pattern.svg'
import CTACard from './cards/ctaCard'
import ContentfulCard from './contentfulCard'
import SmallText from './typography/smallText'
import { Heading } from './typography/headings'
import BodyTextSections from './bodyTextSections'
import { zIndex } from '../utils/constants'
import LeadooBot from './leadoo/inpageBot'

const propTypes = {
  cardGroupsToggle: shape({
    enabled: bool,
    defaultIndex: number,
  }),
  floatingElement: shape({
    visible: bool,
    render: func,
  }),
}

const NONE = 'none'

const isNoneHero = heroType => heroType === NONE

const shouldShowBodyAfterCards = pageSlug => {
  return ['rahastot', 'rikasta-elamaa'].includes(pageSlug)
}

const getImageProps = ({ image, slug }) => ({
  ...image,
  ...({
    tuottolaskuri: {
      coverCss: `background: transparent url('${PatternImgUrl}') 0 0 /cover repeat;`,
    },
  }[slug] || {}),
})

const FooterTextContent = styled(TextContent)`
  padding-top: 1em;
`

const FooterContent = styled.p``

const ContainerWithPadding = styled(Container)`
  padding-bottom: 2rem;
  padding-top: 2rem;
`

// prettier-ignore
const FloatingElement = styled.div(({ theme, visible }) => `
  position: fixed;
  bottom: ${theme.spacing.xlarge};
  left: ${theme.spacing.xlarge};
  z-index: ${zIndex.floatingButton};
  cursor: pointer;
  transition: all ${theme.transition.duration} ${theme.transition.timingFunction};
  ${!visible && 'transform: scale(0); pointer-events: none; opacity: 0;'}

  @media (max-width: ${theme.breakpoints.xs.max}) {
    left: ${theme.spacing.small};
    bottom: ${theme.spacing.small};
  }
`
)

const RelatedCardGroup = styled(CardGroup)`
  padding-top: 0;
`
const getFooterContent = footerObject => {
  const { footer } = footerObject || {}
  return getContentfulLongTextAsNode(footer)
}

const renderAside = data => <CTACard data={data} />

export const getHero = ({
  heroType,
  useConfettiHero,
  heroTheme: heroThemeName,
  image,
  leadParagraph,
  hasSubNavigation,
  heading,
  slug,
  callToActionText,
  callToActionTarget,
  callToActionText2,
  callToActionTarget2,
  callToActionText3,
  callToActionTarget3,
  videoEmbed,
  steps,
  flowProductCards,
  readMoreLinkText,
  readMoreLinkTarget,
}) => {
  const { html: leadParagraphHtml } = leadParagraph?.childMarkdownRemark ?? {}

  const heroProps = {
    hasSubNavigation: hasSubNavigation,
    image:
      isNoneHero(heroType) || !image ? null : getImageProps({ image, slug }),
    heading,
    lead: leadParagraphHtml ? (
      <div dangerouslySetInnerHTML={{ __html: leadParagraphHtml }} />
    ) : null,
    cta: {
      to: callToActionTarget,
      text: callToActionText,
    },
    cta2: {
      to: callToActionTarget2,
      text: callToActionText2,
    },
    cta3: {
      to: callToActionTarget3,
      text: callToActionText3,
    },
    useConfetti: useConfettiHero,
    videoEmbed: videoEmbed ? videoEmbed.videoEmbed : null,
    steps,
    flowProductCards,
    readMoreLinkText,
    readMoreLinkTarget,
  }

  const heroTheme =
    (heroThemeName && getHeroTheme(heroThemeName)) || getHeroThemeBySlug(slug)

  if (heroTheme) {
    return (
      <ThemeProvider theme={heroTheme}>
        <SplitHero {...heroProps} />
      </ThemeProvider>
    )
  }

  return <SplitHero {...heroProps} />
}

export const CardGroups = ({
  cardGroups,
  toggle: { enabled, defaultIndex } = {},
}) => {
  const breakPoints = useBreakpoints() || {}
  if (enabled) {
    return (
      <Accordion
        openIdx={
          typeof defaultIndex === 'number'
            ? defaultIndex
            : !breakPoints.sm
            ? 0
            : undefined
        }
        renderTrigger={props => (
          <Container containerWidth="content">
            <AccordionButton {...props} />
          </Container>
        )}
        sections={cardGroups.map((group, i) => ({
          title: group.hero,
          data: <CardGroup key={i} footer={group.footer} items={group.items} />,
        }))}
      />
    )
  }

  return cardGroups.map((group, i) => <CardGroup key={i} {...group} />)
}

const ContentfulPage = ({
  children,
  page,
  aside,
  cardGroups,
  table,
  cardGroupsToggle,
  subNavigation,
  floatingElement,
  ...rest
}) => {
  const image = page.image && `https:${page.image.file.url}`
  const footerContent = getFooterContent(page.footer)
  const seoHtml = page.seoMarkdown?.childMarkdownRemark?.html
  const showBodyAfterCards = shouldShowBodyAfterCards(page.slug)
  const hubspotFormConfig = page.hubspotFormConfig?.internal?.content
    ? JSON.parse(page.hubspotFormConfig?.internal.content)
    : {}

  const renderBodyAndAside = () => {
    return page.body || page.bodyTextSections || aside || subNavigation ? (
      <LayoutContent
        aside={page.asideBlock ? renderAside(page.aside) : aside}
        subNavigation={subNavigation}
      >
        {page.body && <ContentfulText text={page.body} />}
        {page.bodyTextSections && (
          <BodyTextSections sections={page.bodyTextSections} />
        )}
      </LayoutContent>
    ) : null
  }

  return (
    <Layout
      title={page.title}
      description={page.description.description}
      image={image}
      hero={getHero(page)}
      shareable={page.shareable}
      productGroup={page.productGroup}
      showToolsBar={page.showToolsBar}
      showContactMethods={page.showContactMethods}
      contactMethodsImage={page.contactMethodsImage}
      reservationContainerImage={page.reservationContainerImage}
      stepsBar={page.stepsBar}
      articleCategories={page.articleCategories}
      featuredArticleHighlights={page.featuredArticleHighlights}
      productInfoSections={page.productInfoSections}
      hubspotFormConfig={hubspotFormConfig}
      {...rest}
    >
      {!showBodyAfterCards && renderBodyAndAside()}
      {page.asideBlock && !page.body ? (
        <LayoutContent
          aside={page.asideBlock ? renderAside(page.aside) : aside}
          subNavigation={subNavigation}
        >
          {children}
        </LayoutContent>
      ) : (
        children
      )}
      <LeadooBot slug={page.slug} />
      {cardGroups?.length > 0 && (
        <CardGroups cardGroups={cardGroups} toggle={cardGroupsToggle} />
      )}
      {page.cardGroup?.length > 0 && (
        <RelatedCardGroup
          hero={
            <Heading as="h2" headingSize="3">
              {page.cardGroupTitle}
            </Heading>
          }
          items={page.cardGroup.map((card, index) => (
            <ContentfulCard card={card} index={index} />
          ))}
        />
      )}
      {showBodyAfterCards && renderBodyAndAside()}
      {(table || page.footer) && (
        <Container isFlowing>
          {table}
          {footerContent && (
            <FooterTextContent alignTo="center">
              <FooterContent as={isString(footerContent) ? undefined : 'div'}>
                {footerContent}
              </FooterContent>
            </FooterTextContent>
          )}
        </Container>
      )}
      {seoHtml && (
        <ContainerWithPadding>
          <SmallText as="div" dangerouslySetInnerHTML={{ __html: seoHtml }} />
        </ContainerWithPadding>
      )}
      {floatingElement && (
        <FloatingElement visible={floatingElement.visible}>
          {floatingElement.render()}
        </FloatingElement>
      )}
    </Layout>
  )
}

ContentfulPage.propTypes = propTypes

export default ContentfulPage

export const query = graphql`
  fragment contentfulJobPostingInformation on ContentfulJobPosting {
    __typename
    slug
    image {
      gatsbyImageData(width: 768)
      title
    }
    previewHeading
    previewBody {
      previewBody
    }
  }

  fragment contentfulStructuredInvestmentInformation on ContentfulStructuredInvestment {
    __typename
    slug
    image {
      gatsbyImageData(width: 768)
      title
    }
    previewHeading
    previewBody {
      previewBody
    }
  }

  fragment contentfulPageInformation on ContentfulPage {
    slug
    hasSubNavigation
    heroType
    heroTheme
    callToActionText
    callToActionTarget
    callToActionText2
    callToActionTarget2
    callToActionText3
    callToActionTarget3
    readMoreLinkText
    readMoreLinkTarget
    title
    description {
      description
    }
    image {
      file {
        url
      }
      title
      gatsbyImageData(width: 1200, placeholder: BLURRED)
    }
    mobileImage {
      file {
        url
      }
      title
      gatsbyImageData(width: 1200, placeholder: BLURRED)
    }
    videoEmbed {
      videoEmbed
    }
    previewVideo {
      previewVideo
    }

    heading
    leadParagraph {
      childMarkdownRemark {
        html
      }
    }
    body {
      childMarkdownRemark {
        html
      }
    }
    footer {
      footer
    }
    shareable
    seoMarkdown {
      childMarkdownRemark {
        html
      }
    }
    asideHeading
    asideBlock
    aside {
      __typename
      ... on Node {
        ... on ContentfulPage {
          slug
          title
        }

        ... on ContentfulArticle {
          slug
          title
        }
        ... on ContentfulPerson {
          slug
          title
          image {
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          firstName
          lastName
          phoneNumber
          email
        }
        ... on ContentfulCallToActionCard {
          __typename
          name
          heading
          body {
            childMarkdownRemark {
              html
            }
          }
          buttonText
          buttonTarget {
            ... on ContentfulPage {
              slug
            }
          }
        }
      }
    }
    addMsClarityTracking
    hubspotFormConfig {
      internal {
        content
      }
    }
    cardsTitle
    cards {
      __typename
      ... on Node {
        ... on ContentfulArticle {
          __typename
          slug
          category
          contentType
          image {
            title
            gatsbyImageData(width: 1200, placeholder: BLURRED)
          }
          previewHeading
          previewHeroHeading
          previewBody {
            previewBody
          }
          publicationDate
        }
        ... on ContentfulCareerPost {
          __typename
          slug
          image {
            title
            gatsbyImageData(width: 1200, placeholder: BLURRED)
          }
          previewHeading
          previewBody {
            previewBody
          }
        }
        ... on ContentfulFund {
          __typename
          slug
          image {
            title
            gatsbyImageData(width: 1200, placeholder: BLURRED)
          }
          previewHeading
          riskRating
          previewBody {
            previewBody
          }
        }
        ... on ContentfulInsurance {
          __typename
          slug
          image {
            title
            gatsbyImageData(width: 1200, placeholder: BLURRED)
          }
          previewHeading
          previewBody {
            previewBody
          }
        }
        ... on ContentfulJobPosting {
          ...contentfulJobPostingInformation
        }
        ... on ContentfulPodcastEpisode {
          slug
          contentType
          category
          seasonNumber
          publicationDate
          image {
            title
            gatsbyImageData(width: 1200, placeholder: BLURRED)
          }
          previewHeading
          previewBody {
            previewBody
          }
          previewHeroHeading
        }
        ... on ContentfulPage {
          __typename
          slug
          image {
            title
            gatsbyImageData(width: 1200, placeholder: BLURRED)
          }
          mobileImage {
            title
            gatsbyImageData(width: 1200, placeholder: BLURRED)
          }
          previewHeading
          previewBody {
            previewBody
          }
          previewHeroBody {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulCard {
          __typename
          slug
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          previewHeading
          previewBody {
            previewBody
          }
          longHeading {
            longHeading
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulCallToActionCard {
          __typename
          name
          heading
          body {
            childMarkdownRemark {
              html
            }
          }
          buttonText
          buttonTarget {
            ... on ContentfulPage {
              slug
            }
          }
        }
        ... on ContentfulDepartment {
          id
          title
          people {
            title
            firstName
            lastName
            slug
            phoneNumber
            email
            office {
              city
              location {
                lat
                lon
              }
            }
            allOffices {
              id
              city
              location {
                lat
                lon
              }
            }
            image {
              title
              gatsbyImageData(height: 200, placeholder: BLURRED)
            }
          }
        }
        ... on ContentfulStructuredInvestment {
          ...contentfulStructuredInvestmentInformation
          leadParagraph {
            leadParagraph
          }
        }
        ... on ContentfulHeroSection {
          __typename
          heading
          contentStyle
          category
          teasers {
            heading
            id
            richTextJson
            cta {
              to
              text
            }
          }
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          content {
            raw
          }
        }
        ... on ContentfulAssetManagementPortfolio {
          __typename
          id
          title
          body {
            childMarkdownRemark {
              html
            }
          }
          allocations {
            ... on ContentfulAssetManagementPortfolioAllocation {
              id
              title
              market
              range
            }
          }
        }
      }
    }
    cardGroupTitle
    cardGroup {
      __typename
      slug
      image {
        title
        gatsbyImageData(width: 768, placeholder: BLURRED)
      }
      previewHeading
      previewBody {
        previewBody
      }
    }
    productGroup {
      __typename
      ... on ContentfulProductGroup {
        __typename
        name
        title
        linkText
        link
        products {
          name
          title
          text {
            text
          }
          footerText
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          theme
          link
        }
      }
    }
    featuredArticleHighlights {
      __typename
      ... on ContentfulFeaturedArticleHighlight {
        __typename
        name
        image {
          title
          gatsbyImageData(width: 768, placeholder: BLURRED)
        }
        title
        text {
          childMarkdownRemark {
            html
          }
        }
        linkText
        link
        displayImageOnRight
      }
    }
    steps {
      __typename
      ... on ContentfulStep {
        __typename
        name
        heading
        altHeading
        buttonTexts
        description {
          description
        }
        altDescription {
          altDescription
        }
        image {
          title
          gatsbyImageData(width: 768, placeholder: BLURRED)
        }
      }
    }
    flowProductCards {
      __typename
      ... on ContentfulFlowProductCard {
        __typename
        title
        text {
          text
        }
        footerText
        image {
          title
          gatsbyImageData(width: 768, placeholder: BLURRED)
        }
        slug
      }
    }
    showToolsBar
    showContactMethods
    contactMethodsImage {
      title
      gatsbyImageData(width: 768, placeholder: BLURRED)
    }
    reservationContainerImage {
      title
      gatsbyImageData(width: 768, placeholder: BLURRED)
    }
    stepsBar {
      __typename
      ... on ContentfulStepsBar {
        __typename
        name
        titles
        texts
        buttonText
      }
    }
    articleCategories {
      __typename
      ... on ContentfulArticleCategories {
        __typename
        title
        categories
        ctaText
      }
    }
    productInfoSections {
      __typename
      ... on Node {
        ... on ContentfulProductInfoModule {
          name
          linksTitle
          linkTitle
          textField {
            childMarkdownRemark {
              html
            }
          }
          additionalTextField {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
