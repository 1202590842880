import React, { useState } from 'react'
import { node, oneOfType, shape, string } from 'prop-types'
import { useLocation } from '@reach/router'
import styled from 'styled-components'
import { imagePropTypes, locationPropTypes } from '../../utils/prop-types'
import { lightenBackground } from '../../utils'
import Link from '../links/link'
import Container from '../container'
import CtaButton from '../buttons/ctaButton'
import Button from '../buttons/button'
import Lead from '../typography/lead'
import { Heading } from '../typography/headings'
import Confetti from '../confetti'
import loadable from '@loadable/component'
import { Loader } from '../centered/absolutelyCentered'
import FlowStep1 from './flowSteps/flowStep1'
import FlowStep2View1 from './flowSteps/flowStep2View1'
import FlowStep2View2 from './flowSteps/flowStep2View2'
import FlowStep3 from './flowSteps/flowStep3'
import ProductCard from './productCard'
import ContactForm from '../contactForm'
import { ReactComponent as ArrowLeftIcon } from '../../images/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from '../../images/arrow-right.svg'
import { ReactComponent as ArrowRightCircleIcon } from '../../images/arrow-with-circle-white.svg'

const OfficeMap = loadable(() => import('../officeMap'), {
  fallback: <Loader />,
})

const propTypes = {
  image: imagePropTypes,
  heading: oneOfType([node, string]).isRequired,
  lead: oneOfType([node, string]),
  className: string,
  cta: shape({
    text: string,
    to: string,
  }),
  cta2: shape({
    text: string,
    to: string,
  }),
  map: shape({
    location: locationPropTypes,
  }),
  videoEmbed: oneOfType([node, string]),
}

const HeroConfetti = styled(Confetti)``

const Wrapper = styled.div(
  ({ theme: { colors, navHeight, breakpoints }, hasImage, hasSubNav }) => `
  color: ${colors.primaryColor};
  background: ${colors.backgroundColor};
  padding: 0;
  position: relative;
  overflow: hidden;
  z-index: 5;
  display: flex;
  justify-content: top;
  flex-direction: column;
  min-height: 240px;
  margin-top: ${navHeight.min};
  background-clip: content-box;

  ${HeroConfetti} {
    z-index: -1 !important;
  }

  @media (min-width: ${breakpoints.md.min}) {
    padding: 0;
  }

  @media (min-width: ${breakpoints.lg.min}) {
    padding-top: ${navHeight.lg};
    margin-top: ${hasSubNav ? navHeight.min : 0};
    min-height: ${hasImage ? '560px' : '320px'};
  }

  @media (max-width: ${breakpoints.md.max}) {
    min-height: 100px;
  }
`
)

export const ImageWrap = styled.div(
  ({
    theme: { spacing, breakpoints },
    blur,
    image,
    showImage,
    productPage,
  }) => `
  position: relative;
  margin-bottom: 0;
  padding-top: 77.5%;
  overflow: hidden;
  display: ${showImage ? 'block' : 'none'};
  border-radius: ${productPage ? '30px' : '0'};
  align-self: center;

  min-width: ${productPage ? '640px' : '670px'};
  max-width: ${productPage ? '640px' : '670px'};
  height: ${productPage ? '480px' : '100%'};

  background-image: url(${image});
  background-size: cover;
  background-position: center center;

  @media (max-width: 1350px) {
    min-width: unset;
    max-width: unset;
  }

  @media (min-width: ${breakpoints.md.min}) {
    padding-top: 50%;
  }

  @media (min-width: ${breakpoints.lg.min}) {
    padding-top: 33%; // a precaution mainly

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: ${blur ? '-10px' : '0'};
      width: calc(100% + 20px);
      height: 100%;
      background-image: ${blur ? 'inherit' : 'none'};
      filter: blur(${blur ? '10px' : '0'});
      z-index: 1;
    }
  }

  @media (max-width: ${breakpoints.sm.max}) {
    margin-left: -${spacing.medium};
    margin-right: -${spacing.medium};
  }

  @media (max-width: ${breakpoints.md.max}) {
    display: block;
    margin-left: -${spacing.xlarge};
    margin-right: -${spacing.xlarge};
    margin-bottom: 20px;
    border-radius: 0;
    height: 230px;
  }
`
)

const HeroLead = styled(Lead).attrs({
  cssOnShortLead: () => 'font-size: 1.333em;',
})`
  &:not(:only-child) {
    margin-bottom: 1.5em;
  }

  > div > p {
    margin-bottom: 0;
  }
`

const BottomContent = styled.div(
  ({ theme: { breakpoints }, highHero }) => `
  display: flex;
  flex-direction: column;
  gap: ${highHero ? '25px' : '45px'};
  margin-right: auto;

  @media (max-width: ${breakpoints.md.max}) {
    gap: 0;
  }
`
)

const Content = styled.div(
  ({
    theme: { breakpoints },
    flow,
    noBottomContent,
    hasImage,
    productPage,
    contactPage,
    hasVideo,
  }) => `
  width: 100%;
  height: ${flow ? '100%' : 'auto'};
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: ${noBottomContent ? 'center' : 'flex-start'};
  padding-left: ${hasImage ? '20px' : '0'};

  @media (max-width: ${breakpoints.md.max}) {
    flex-direction:  column;
    padding-left: 0;
    padding-bottom: ${productPage ? '48px' : '0'};
    padding-top: ${hasVideo ? '20px' : '0'};
  }

  ${Heading},
  ${BottomContent} > * {
    margin-bottom: ${contactPage ? '100px !important' : 0};
    text-align: ${contactPage ? 'center !important' : 'left'};
    color: inherit;
    z-index: 10;

    @media (max-width: ${breakpoints.md.max}) {
      margin: ${
        flow ? 0 : contactPage ? '20px 0 !important' : '0 0 20px 0 !important'
      };
    }
  }

  ${Heading} {
    text-align: left;
    max-width: 600px;
    font-size: 60px;
    line-height: 70px;

    &:only-child {
      margin-bottom: 0;
    }

    @media (max-width: ${breakpoints.lg.max}) {
      font-size: 28px;
      line-height: 33px;
    }
  }

  
  ${ImageWrap} + &,
  ${EmbedResponsive} + & {
    ${Heading} {
      text-align: left;
      width: 100%;
      word-break: break-word;
    }
  }
  
  @media screen and (max-width: ${breakpoints.sm.max}) {
    ${Heading} {
      word-break: break-word;
    }
  }
  
  @media (max-width: ${breakpoints.md.max}) {
    ${ImageWrap} + & {
      padding-top: 0;
    }
  }
  `
)

const CTALinkContainer = styled.div(
  ({ theme: { breakpoints, colors }, isFrontPage }) => `
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: ${isFrontPage ? 0 : '20px'};

  @media (max-width: ${breakpoints.md.max}) {
    gap: 10px;
    margin-top: 0;
  }

  p {
    margin: 0;
  }
`
)

const CtaButtonDefault = styled(CtaButton)(
  ({ theme: { breakpoints, colors } }) => `
  display: flex;
  width: fit-content;
  padding: 10px 30px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  gap: 15px;
  align-items: center;
  background-color: ${colors.primaryColor};
  color: ${colors.backgroundColor} !important;
  border-color: ${colors.primaryColor};

  svg {
    min-width: 25px;
    path {
      fill: ${colors.backgroundColor} !important;
    }
  }

  &:hover {
    transform: scale(1.05);
    transition: transform 0.5s;

    svg {
      path {
        fill: ${colors.red};
      }
    }
  }

  @media (max-width: ${breakpoints.md.max}) {
    padding: 10px 20px;
    font-size: 16px;
    line-height: 20px;
  }
`
)

const HeroContainer = styled(Container)(
  ({
    theme: { breakpoints, navHeight },
    hasSubNav,
    flow,
    highHero,
    lowHero,
    productPage,
    contactPage,
    hasVideo,
    isFrontPage,
  }) => `
  height: ${
    lowHero
      ? '315px'
      : productPage
      ? '542px'
      : contactPage
      ? '501px'
      : hasSubNav
      ? highHero
        ? '700px'
        : `calc(670px - ${navHeight.min})`
      : '670px'
  };

  ${hasVideo ? 'max-width: 80%' : ''};

  @media (min-width: ${breakpoints.lg.min}) {
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: space-between;
    gap: ${hasVideo ? '20px' : '90px'};

    @media (max-width: ${breakpoints.lg.max}) {
      gap: ${hasVideo ? '20px' : '45x'};
    }

    ${ImageWrap},
    ${ImageWrap} + ${Content} {
      margin-bottom: 0;
    }

    ${ImageWrap} + ${Content} {
      width: 50%;

      ${BottomContent} {
        max-width: 560px;
      }
    }
    ${ImageWrap} {
      width: 670px;

      @media (max-width: 1210px) {
        width: 50%;
      }
    }

    ${Content} {
      display: flex;
      flex-direction: column;
      gap: ${flow ? '0' : highHero ? '25px' : '45px'};
      justify-content: ${flow ? 'space-between' : 'center'};
      padding-bottom: ${flow ? '48px' : '0'};

      &:only-child {
        margin: 0 auto;
      }
    }
  }

  @media (min-width: ${breakpoints.md.max}) and (max-width: 1100px) {
    gap: 90px;
  }

  @media (max-width: ${breakpoints.md.max}) {
    ${hasVideo ? 'margin-left: 0' : ''};
    ${hasVideo ? 'margin-right: 0' : ''};
    max-width: 100%;
    height: ${contactPage ? '220px' : 'auto'};
    padding-left: ${hasVideo ? '16px' : '20px'};
    padding-right: 20px;
  }
`
)

const HeroButton = styled(Button)(
  ({ theme: { breakpoints, colors } }) => `
  display: flex;
  border: none;
  padding: 15px 30px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  background-color: ${colors.primaryColor};
  color: ${colors.red} !important;
  gap: 15px;
  align-items: center;

  &:hover {
    transform: scale(1.05);
    transition: transform 0.5s;

    svg {
      path {
        fill: ${colors.red};
      }
    }
  }

  svg {
    min-width: 25px;
    path {
      fill: ${colors.red};
    }
  }

  @media (max-width: ${breakpoints.md.max}) {
    padding: 10px 20px;
    font-size: 16px;
    gap: 10px;
  }
`
)

const ReadMoreLinkContainer = styled(CtaButton)(
  ({ theme: { breakpoints } }) => `
  display: flex;
  align-items: center;
  border: none;
  padding: 0;

  svg {
    min-width: 40px;
    min-height: 40px;
  }

  &:hover, &:active, &:focus {
    text-decoration: underline;
    background-color: transparent;
  }
`
)

const CtaLink = styled(Link)(
  ({ theme: { breakpoints, colors } }) => `
  display: flex;
  padding: 15px;
  color: ${colors.primaryColor};
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;

  @media (max-width: ${breakpoints.md.max}) {
    padding: 10px 20px;
    font-size: 16px;
  }
`
)

const EmbedResponsive = styled.div(
  ({ theme }) => `
  display: block;
  position: relative;
  overflow: visible;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 100px;
  padding-right: 100px;

  &:before {
    content: "";
    display: block;
  }
  
  iframe {
    position: absolute;
    top: .75em;
    left: 1em;
  }
  
  @media screen and (min-width: ${theme.breakpoints.xl.min}) {
    iframe {
      max-width: 48vw;
    }
  }
  
  @media screen and (min-width: ${theme.breakpoints.md.min}) and (max-width: ${theme.breakpoints.lg.max}) {
    iframe {
      top: 0;
      width: 100%;
      height: 30vw;
    }
  }

  @media screen and (max-width: ${theme.breakpoints.md.max}) {
    margin-left: -${theme.spacing.medium};
    margin-right: -${theme.spacing.medium};
    margin-top: 0;
    padding-right: 0;
    width: auto;
    height: 58vw;
    iframe {
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
    }
  }

  @media (max-width: ${theme.breakpoints.sm.max}) {
    margin-left: -${theme.spacing.medium};
    margin-right: -${theme.spacing.medium};
  }
`
)

const VideoContainer = styled.div(
  () => `
  flex-grow: 1;
`
)

const FlowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 520px;
  margin-top: auto;
  margin-bottom: auto;
  gap: 25px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
    width: 100%;
    gap: 20px;
    margin: 0 auto;
  }
`

const ButtonRow = styled.div(
  ({ theme: t }) => `
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 27px;

  @media (min-width: ${t.breakpoints.md.max}) and (max-width: ${t.breakpoints.lg.max}) {
    gap: 11px;
  }

  @media (max-width: ${t.breakpoints.md.max}) {
    margin-bottom: 20px;
    gap: 11px;
  }
`
)

const StepButton = styled(Button)(
  ({ theme: t }) => `
  display: flex;
  width: 200px;
  height: 45px;
  padding: 13px 14px 12px 11px;
  background-color: transparent;
  color: ${t.colors.primaryColor};
  border-width: 2px;
  align-items: center;

  span {
    width: 154px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;

    @media (max-width: ${t.breakpoints.md.max}) {
      width: 83px;
      font-size: 14px;
      line-height: normal;
    }

    @media (max-width: 380px) {
      width: fit-content;
    }
  }

  &:active, &:focus {
    background-color: transparent;
    color: ${t.colors.primaryColor};

    svg {
      path {
        fill: ${t.colors.primaryColor};
      }
    }
  }

  &:hover {
    background-color: ${t.colors.primaryColor};
    color: ${t.colors.red};

    svg {
      path {
        fill: ${t.colors.red};
      }
    }
  }

  @media (max-width: 1200px) {
    width: 130px;
    height: 35px;
    padding: 4px 12px 3px 10px;
    font-size: 14px;
    line-height: normal;

    span {
      width: 83px;
    }

    &:active, &:focus {
      background-color: transparent;
      color: ${t.colors.primaryColor};
  
      svg {
        path {
          fill: ${t.colors.primaryColor};
        }
      }
    }
  }

  @media (max-width: 380px) {
    width: 100%;

    span {
      width: 60px;
    }
  }
`
)

const PreviousButton = styled(StepButton)(
  ({ hidden }) => `
  visibility: ${hidden ? 'hidden' : 'visible'};
`
)

const NextButton = styled(StepButton)(
  ({ theme: t, hidden, disabled }) => `
  padding: 13px 11px 12px 14px;
  visibility: ${hidden ? 'hidden' : 'visible'};
  opacity: ${disabled ? 0.5 : 1};
  pointer-events: ${disabled ? 'none' : 'auto'};
  background-color: ${disabled ? 'transparent' : t.colors.primaryColor};
  color: ${disabled ? t.colors.primaryColor : t.colors.red} !important;
  border: ${disabled ? '2px solid' : 'none'};

  svg {
    path {
      fill: ${disabled ? t.colors.primaryColor : t.colors.red};
    }
  }

  &:hover {
    transform: ${disabled ? 'none' : 'scale(1.05)'};
    transition: transform 0.5s;
  }

  @media (max-width: ${t.breakpoints.md.max}) {
    padding: 4px 10px 3px 12px;
  }
`
)

const Stepper = styled.div`
  display: flex;
  gap: 10px;
`

const Step = styled.div(
  ({ theme: t, completed, active }) => `
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid ${lightenBackground(t.colors.pinkLight, 0.5)};
  background-color: ${completed || active ? t.colors.white : 'transparent'};
  opacity: ${active ? 1 : 0.4};
`
)

const CardsWrapper = styled.div(
  ({ theme: t }) => `
  position: absolute;
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  bottom: 30px;
  right: 30px;
  z-index: 2;

  @media (max-width: 1300px) {
    width: calc(100% - 30px);
    bottom: 15px;
    right: 15px;
  }

  @media (max-width: ${t.breakpoints.lg.min}) {
    display: none;
  }
`
)

const CardsWrapperMobile = styled.div(
  ({ theme: t }) => `
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-direction: column;
  margin-bottom: 30px;

  div:first-child {
    height: auto !important;
  }

  @media (min-width: ${t.breakpoints.md.max}) {
    display: none;
  }
`
)

const PopupContainer = styled.div(
  ({ theme: t }) => `
  display: flex;
  flex-direction: column;
  min-width: 670px;
  max-width: 670px;
  height: 570px;
  border-radius: 30px;
  background-color: ${t.colors.white};
  color: ${t.colors.primaryColor};
  align-items: center;
  justify-content: center;
  padding: 59px 90px 57px 90px;

  @media (max-width: 1350px) {
    min-width: unset;
    max-width: unset;
    width: 100%;
  }
`
)

const FormContainer = styled(PopupContainer)(
  ({ theme: t }) => `
  div {
    width: 100%;
  }

  h3 {
    font-size: 28px;
    line-height: 33px;

    @media (max-width: ${t.breakpoints.md.max}) {
      font-size: 22px;
      line-height: 24px;
    }
  }

  p {
    font-size: 16px;
    line-height: 20px;
    margin: 30px 0 15px 0;
  }

  input {
    width: 480px
    max-width: 480px;
  }

  form {
    flex-direction: column;

    div:nth-child(2) {
      justify-content: flex-end;
    }
  }
`
)

const CalendarContainer = styled(PopupContainer)(
  ({ theme: t }) => `
  padding: 0;
  overflow: hidden;
`
)

const CalendarContainerDesktop = styled(CalendarContainer)(
  ({ theme: t }) => `
  @media (max-width: ${t.breakpoints.md.max}) {
    display: none;
  }

  margin-top: 50px;
`
)

const CalendarContainerMobile = styled(CalendarContainer)(
  ({ theme: t }) => `
  @media (min-width: ${t.breakpoints.md.max}) {
    display: none;
  }

  margin-bottom: 20px;
`
)

const FormContainerDesktop = styled(FormContainer)(
  ({ theme: t }) => `
  @media (max-width: ${t.breakpoints.md.max}) {
    display: none;
  }

  margin-top: 50px;
`
)

const FormContainerMobile = styled(FormContainer)(
  ({ theme: t }) => `
  @media (min-width: ${t.breakpoints.md.max}) {
    display: none;
  }

  height: auto;
  padding: 41px 20px 44px 20px;
  margin: 0 0 20px 0;
`
)

const FormButtons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: 15px;
  gap: 5px;

  > * {
    padding: 0 1em;
  }
`

const FormButton = styled.button(
  ({ theme: t, isSelected }) => `
  height: 36px;
  width: 135px;
  background-color: ${
    isSelected ? t.colors.pink : lightenBackground(t.colors.pink, 0.8)
  };
  color: ${t.colors.primaryColor};
  border: none;
  border-radius: 40px;
  outline: none;
  font-size: 16px;
  line-height: 20px;

  &:hover {
    background-color: ${lightenBackground(t.colors.pink, 0.5)};
  }
`
)

const initialSelectedFlowOptions = [
  { step: 1, choices: null },
  { step: 2, choices: null },
  { step: 2.5, choices: null },
  { step: 3, choices: null },
]

const SplitHero = ({
  hasSubNavigation,
  image,
  map,
  heading,
  lead,
  className,
  cta: { text, to } = {},
  cta2 = {},
  cta3 = {},
  useConfetti,
  videoEmbed,
  steps,
  flowProductCards,
  readMoreLinkText,
  readMoreLinkTarget,
  productPage,
  availableOnline,
}) => {
  const location = useLocation()
  const currentPath = location.pathname
  // const isFrontPage = currentPath === '/'
  const isFrontPage = false
  const isSisallot = currentPath === '/sisallot'
  const isContactPage = currentPath === '/toimipaikat-ja-yhteystiedot'
  const isRahastoProduct = currentPath.includes('/rahastot/')

  const [showFlow, setShowFlow] = useState(false)
  const [previousSteps, setPreviousSteps] = useState([])
  const [currentStep, setCurrentStep] = useState(0)
  const [selectedFlowOptions, setSelectedFlowOptions] = useState(
    initialSelectedFlowOptions
  )
  const [suggestRahastot, setSuggestRahastot] = useState(false)
  const [suggestVakuutussijoittaminen, setSuggestVakuutussijoittaminen] =
    useState(false)
  const [suggestVarainhoito, setSuggestVarainhoito] = useState(false)
  const [suggestStrukturoidut, setSuggestStrukturoidut] = useState(false)
  const [suggestYrityksenSijoitukset, setSuggestYrityksenSijoitukset] =
    useState(false)
  const [step3AlternativeView, setStep3AlternativeView] = useState(false)
  const [blurImage, setBlurImage] = useState(false)
  const [showImage, setShowImage] = useState(true)
  const [showCalendar, setShowCalendar] = useState(false)
  const [showContactForm, setShowContactForm] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [contactType, setContactType] = useState('tel')
  const [tavoitteet, setTavoitteet] = useState([])
  const [sijoitusaika, setSijoitusaika] = useState([])
  const [isSubmitted, setIsSubmitted] = useState(false)

  const noBottomContent = !(lead || to)

  const handleFormSubmit = success => {
    setIsSubmitted(success)
  }

  const handleSelectedFlowOptionsChange = (step, newOptions) => {
    setShowCalendar(false)
    setShowContactForm(false)
    setShowChat(false)
    setShowImage(true)

    setSelectedFlowOptions(prevFlowOptions => {
      const updatedFlowOptions = prevFlowOptions.map(option => {
        if (option.step === step) {
          return { step, choices: newOptions }
        }
        return option
      })

      if (currentStep === 3) {
        if (updatedFlowOptions[3].choices.includes(0)) {
          setShowCalendar(true)
          setShowImage(false)
        } else if (updatedFlowOptions[3].choices.includes(1)) {
          setShowContactForm(true)
          setShowImage(false)
        } else if (updatedFlowOptions[3].choices.includes(2)) {
          setShowChat(true)
        }
      }

      return updatedFlowOptions
    })
  }

  const handleButtonClick = () => {
    setCurrentStep(1)
    setShowFlow(true)
  }

  const validateOptions = () => {
    setSuggestRahastot(false)
    setSuggestStrukturoidut(false)
    setSuggestVakuutussijoittaminen(false)
    setSuggestVarainhoito(false)
    setSuggestYrityksenSijoitukset(false)
    setStep3AlternativeView(false)
    setBlurImage(false)

    if (selectedFlowOptions[0].choices.length === 1) {
      if (selectedFlowOptions[0].choices.includes(0)) {
        if (selectedFlowOptions[1].choices) {
          if (
            selectedFlowOptions[1].choices.includes(1) ||
            selectedFlowOptions[1].choices.includes(2)
          ) {
            setSuggestRahastot(true)
            setSuggestStrukturoidut(true)
            setSuggestVakuutussijoittaminen(true)
            setSuggestVarainhoito(true)
            setBlurImage(true)
            if (selectedFlowOptions[2].choices) {
              if (selectedFlowOptions[2].choices.includes(0)) {
                setSuggestStrukturoidut(false)
                setSuggestVakuutussijoittaminen(false)
                setSuggestVarainhoito(false)
              }
            }
          } else {
            setSuggestRahastot(false)
            setSuggestStrukturoidut(false)
            setSuggestVakuutussijoittaminen(false)
            setSuggestVarainhoito(false)
            setStep3AlternativeView(true)
          }
        }
      } else if (
        selectedFlowOptions[0].choices.includes(1) ||
        selectedFlowOptions[0].choices.includes(2) ||
        selectedFlowOptions[0].choices.includes(3)
      ) {
        if (selectedFlowOptions[1].choices) {
          if (
            selectedFlowOptions[1].choices.includes(1) ||
            selectedFlowOptions[1].choices.includes(2)
          ) {
            setSuggestRahastot(true)
            setSuggestStrukturoidut(true)
            setSuggestVakuutussijoittaminen(true)
            setBlurImage(true)
            if (selectedFlowOptions[2].choices) {
              if (selectedFlowOptions[2].choices.includes(0)) {
                setSuggestStrukturoidut(false)
                setSuggestVakuutussijoittaminen(false)
                setSuggestVarainhoito(false)
              }
            }
          } else {
            setSuggestRahastot(false)
            setSuggestStrukturoidut(false)
            setSuggestVakuutussijoittaminen(false)
            setSuggestVarainhoito(false)
            setStep3AlternativeView(true)
          }
        }
      } else if (selectedFlowOptions[0].choices.includes(4)) {
        setSuggestYrityksenSijoitukset(true)
        setBlurImage(true)
      } else if (selectedFlowOptions[0].choices.includes(5)) {
        setStep3AlternativeView(true)
      }
    } else {
      setStep3AlternativeView(true)
    }
  }

  const handlePrevious = () => {
    setShowCalendar(false)
    setShowContactForm(false)
    setShowChat(false)
    setShowImage(true)

    if (currentStep === 1) {
      setCurrentStep(0)
      setShowFlow(false)
    }

    if (previousSteps.length > 0) {
      const newPreviousSteps = [...previousSteps]
      const prevStep = newPreviousSteps.pop()
      setPreviousSteps(newPreviousSteps)
      setCurrentStep(prevStep)
    }
  }

  const handleNext = () => {
    const newPreviousSteps = [...previousSteps, currentStep]
    setPreviousSteps(newPreviousSteps)

    if (currentStep === 1) {
      if (selectedFlowOptions[0].choices.length === 1) {
        if (selectedFlowOptions[0].choices.includes(4)) {
          setCurrentStep(3)
        } else if (selectedFlowOptions[0].choices.includes(5)) {
          setCurrentStep(3)
        } else {
          setCurrentStep(2)
        }
      } else {
        setCurrentStep(3)
      }
    } else if (currentStep === 2) {
      if (selectedFlowOptions[1].choices.includes(0)) {
        setCurrentStep(3)
      } else setCurrentStep(2.5)
    } else if (currentStep === 2.5) {
      setCurrentStep(3)
    }

    validateOptions()
    setTavoitteet(getTavoitteet())
    setSijoitusaika(getSijoitusaika())
  }

  const getTavoitteet = () => {
    const choices = []

    const buttonTexts = steps[0].buttonTexts

    selectedFlowOptions[0].choices &&
      selectedFlowOptions[0].choices.forEach((choiceIndex, index) => {
        choices.push(buttonTexts[choiceIndex])
        if (index < selectedFlowOptions[0].choices.length - 1) {
          choices.push(', ')
        }
      })

    return choices.join('')
  }

  const getSijoitusaika = () => {
    if (selectedFlowOptions[2].choices) {
      if (selectedFlowOptions[2].choices.includes(0)) {
        return '5-10 vuotta'
      } else if (selectedFlowOptions[2].choices.includes(1)) {
        return '10 vuotta'
      }
    }
    return ''
  }

  const buttonIsDisabled = step => {
    return (
      !selectedFlowOptions[step] ||
      !selectedFlowOptions[step].choices ||
      selectedFlowOptions[step].choices.length === 0
    )
  }

  const getHeroImage = () => {
    if (currentStep === 1) {
      return steps[0].image.gatsbyImageData.images.fallback.src
    } else if (currentStep === 2) {
      return steps[1].image.gatsbyImageData.images.fallback.src
    } else if (currentStep === 2.5) {
      return steps[2].image.gatsbyImageData.images.fallback.src
    } else if (currentStep === 3) {
      return steps[3].image.gatsbyImageData.images.fallback.src
    } else if (
      image &&
      image.gatsbyImageData &&
      image.gatsbyImageData.images.fallback.src
    ) {
      return image.gatsbyImageData.images.fallback.src
    }

    return null
  }

  return (
    <Wrapper
      className={className}
      hasImage={!!(image || map || useConfetti)}
      hasSubNav={hasSubNavigation}
    >
      <HeroContainer
        containerWidth={image || map ? undefined : 'content'}
        hasSubNav={hasSubNavigation}
        flow={showFlow}
        highHero={to && text && !isFrontPage}
        lowHero={isSisallot}
        productPage={productPage}
        contactPage={isContactPage}
        hasVideo={!!videoEmbed}
        isFrontPage={isFrontPage}
      >
        {videoEmbed && (
          <EmbedResponsive>
            <VideoContainer dangerouslySetInnerHTML={{ __html: videoEmbed }} />
          </EmbedResponsive>
        )}
        {(image || map) && !videoEmbed && (
          <ImageWrap
            data-wraps-map={!!map}
            blur={currentStep === 3 && blurImage}
            image={getHeroImage()}
            showImage={showImage}
            productPage={productPage}
          >
            {map ? (
              <OfficeMap {...map.location} />
            ) : (
              <>
                {currentStep === 3 && (
                  <CardsWrapper>
                    {suggestRahastot && (
                      <ProductCard
                        product={flowProductCards[0]}
                        fourCards={
                          suggestVakuutussijoittaminen &&
                          suggestVarainhoito &&
                          suggestStrukturoidut
                        }
                      />
                    )}
                    {suggestVakuutussijoittaminen && (
                      <ProductCard
                        product={flowProductCards[1]}
                        fourCards={
                          suggestRahastot &&
                          suggestVarainhoito &&
                          suggestStrukturoidut
                        }
                      />
                    )}
                    {suggestVarainhoito && (
                      <ProductCard
                        product={flowProductCards[2]}
                        fourCards={
                          suggestRahastot &&
                          suggestVakuutussijoittaminen &&
                          suggestStrukturoidut
                        }
                      />
                    )}
                    {suggestStrukturoidut && (
                      <ProductCard
                        product={flowProductCards[3]}
                        fourCards={
                          suggestRahastot &&
                          suggestVakuutussijoittaminen &&
                          suggestVarainhoito
                        }
                      />
                    )}
                    {suggestYrityksenSijoitukset && (
                      <ProductCard
                        product={flowProductCards[4]}
                        fourCards={
                          suggestRahastot &&
                          suggestVakuutussijoittaminen &&
                          suggestVarainhoito
                        }
                      />
                    )}
                  </CardsWrapper>
                )}
              </>
            )}
          </ImageWrap>
        )}
        {showCalendar ? (
          <CalendarContainerDesktop>
            <iframe
              src="https://oma.alexandria.fi/ajanvaraus"
              name="calendar"
              frameborder="0"
              height="800px"
              width="100%"
            ></iframe>
          </CalendarContainerDesktop>
        ) : showContactForm ? (
          <FormContainerDesktop>
            <div>
              {!isSubmitted && (
                <>
                  <h3>Jätä yhteydenottopyyntö</h3>
                  <p>Haluan että minuun ollaan yhteydessä:</p>
                  <FormButtons>
                    <FormButton
                      isSelected={contactType === 'tel'}
                      onClick={() => setContactType('tel')}
                    >
                      Puhelimitse
                    </FormButton>
                    <FormButton
                      isSelected={contactType === 'email'}
                      onClick={() => setContactType('email')}
                    >
                      Sähköpostitse
                    </FormButton>
                  </FormButtons>
                </>
              )}
              <ContactForm
                contactType={contactType}
                popup={true}
                onSubmit={handleFormSubmit}
                whiteTheme={false}
                popupFormSubmitted={isSubmitted}
                thankYouTitle={'Kiitos yhteydenottopyynnöstä!'}
                thankYouMessage={`Olemme sinuun yhteydessä ${contactType} seuraavan arkipäivän aikana.`}
                choices={
                  <div>
                    <span>Ennakkotiedot:</span> {tavoitteet}{' '}
                    {sijoitusaika && ' | Sijoitusaika ' + sijoitusaika}
                  </div>
                }
              />
            </div>
          </FormContainerDesktop>
        ) : showChat ? (
          <script
            type="text/javascript"
            async
            src="https://bot.leadoo.com/bot/dynamic.js?company=t6UelyAe"
          ></script>
        ) : null}
        <Content
          flow={showFlow}
          noBottomContent={noBottomContent}
          hasImage={!!(image || map || useConfetti)}
          hasVideo={!!videoEmbed}
          productPage={productPage}
          contactPage={isContactPage}
        >
          {!showFlow ? (
            <>
              <Heading headingSize="2">{heading}</Heading>
              {lead || to || isRahastoProduct ? (
                <BottomContent highHero={to && text && !isFrontPage}>
                  <HeroLead>{lead}</HeroLead>
                  {readMoreLinkText && (
                    <ReadMoreLinkContainer to={readMoreLinkTarget}>
                      <ArrowRightCircleIcon />
                      <CtaLink to={`${currentPath}#productInfo`}>
                        {readMoreLinkText}
                      </CtaLink>
                    </ReadMoreLinkContainer>
                  )}
                  {(isRahastoProduct || (productPage && availableOnline)) && (
                    <CTALinkContainer>
                      <p>
                        <CtaButtonDefault
                          to={'https://oma.alexandria.fi'}
                          large="true"
                          self
                        >
                          {'Tee merkintä'} <ArrowRightIcon />
                        </CtaButtonDefault>
                      </p>
                    </CTALinkContainer>
                  )}
                  {to && text && (
                    <CTALinkContainer isFrontPage={isFrontPage}>
                      <p>
                        {isFrontPage ? (
                          <HeroButton onClick={handleButtonClick}>
                            {text} <ArrowRightIcon />
                          </HeroButton>
                        ) : (
                          <CtaButtonDefault
                            to={to || '/varaa-aika'}
                            large="true"
                          >
                            {text || 'Varaa aika'} <ArrowRightIcon />
                          </CtaButtonDefault>
                        )}
                      </p>
                      {cta2.to && cta2.text && (
                        <p>
                          <CtaButtonDefault to={cta2.to} large="true">
                            {cta2.text} <ArrowRightIcon />
                          </CtaButtonDefault>
                        </p>
                      )}
                      {cta3.to && cta3.text && (
                        <p>
                          <CtaButtonDefault to={cta3.to} large="true">
                            {cta3.text} <ArrowRightIcon />
                          </CtaButtonDefault>
                        </p>
                      )}
                    </CTALinkContainer>
                  )}
                </BottomContent>
              ) : null}
            </>
          ) : (
            <>
              <FlowWrapper>
                {showCalendar ? (
                  <CalendarContainerMobile id="flow-step-3">
                    <iframe
                      src="https://oma.alexandria.fi/ajanvaraus"
                      name="calendar"
                      frameborder="0"
                      height="800px"
                      width="100%"
                    ></iframe>
                  </CalendarContainerMobile>
                ) : showContactForm ? (
                  <FormContainerMobile>
                    <div>
                      {!isSubmitted && (
                        <>
                          <h3>Jätä yhteydenottopyyntö</h3>
                          <p>Haluan että minuun ollaan yhteydessä:</p>
                          <FormButtons>
                            <FormButton
                              isSelected={contactType === 'tel'}
                              onClick={() => setContactType('tel')}
                            >
                              Puhelimitse
                            </FormButton>
                            <FormButton
                              isSelected={contactType === 'email'}
                              onClick={() => setContactType('email')}
                            >
                              Sähköpostitse
                            </FormButton>
                          </FormButtons>
                        </>
                      )}
                      <ContactForm
                        contactType={contactType}
                        popup={true}
                        onSubmit={() => {}}
                        whiteTheme={false}
                        choices={
                          <div>
                            <span>Ennakkotiedot:</span> {tavoitteet}{' '}
                            {sijoitusaika && ' | Sijoitusaika ' + sijoitusaika}
                          </div>
                        }
                      />
                    </div>
                  </FormContainerMobile>
                ) : showChat ? (
                  <script
                    type="text/javascript"
                    async
                    src="https://bot.leadoo.com/bot/dynamic.js?company=t6UelyAe"
                  ></script>
                ) : null}
                {currentStep === 1 && (
                  <FlowStep1
                    step={steps[0]}
                    selectedOptions={selectedFlowOptions[0].choices}
                    onSelectedFlowOptionsChange={
                      handleSelectedFlowOptionsChange
                    }
                  />
                )}
                {currentStep === 2 && (
                  <FlowStep2View1
                    step={steps[1]}
                    selectedOptions={selectedFlowOptions[1].choices}
                    onSelectedFlowOptionsChange={
                      handleSelectedFlowOptionsChange
                    }
                  />
                )}
                {currentStep === 2.5 && (
                  <FlowStep2View2
                    step={steps[2]}
                    selectedOptions={selectedFlowOptions[2].choices}
                    onSelectedFlowOptionsChange={
                      handleSelectedFlowOptionsChange
                    }
                    previousSteps={previousSteps}
                    setPreviousSteps={setPreviousSteps}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                  />
                )}
                {currentStep === 3 && (
                  <FlowStep3
                    step={steps[3]}
                    alternativeView={step3AlternativeView}
                    onSelectedFlowOptionsChange={
                      handleSelectedFlowOptionsChange
                    }
                  />
                )}
              </FlowWrapper>
              <ButtonRow>
                <PreviousButton onClick={handlePrevious}>
                  <ArrowLeftIcon />
                  <span>Edellinen</span>
                </PreviousButton>
                <Stepper>
                  <Step
                    completed={currentStep > 1}
                    active={currentStep === 1}
                  />
                  <Step
                    completed={currentStep > 2.5}
                    active={currentStep === 2 || currentStep === 2.5}
                  />
                  <Step
                    completed={currentStep > 3}
                    active={currentStep === 3}
                  />
                </Stepper>
                <NextButton
                  hidden={currentStep === 3}
                  disabled={buttonIsDisabled(
                    currentStep === 2.5 ? 2 : currentStep - 1
                  )}
                  onClick={handleNext}
                >
                  <span>Seuraava</span>
                  <ArrowRightIcon />
                </NextButton>
              </ButtonRow>
              {currentStep === 3 && (
                <CardsWrapperMobile>
                  {suggestRahastot && (
                    <ProductCard product={flowProductCards[0]} />
                  )}
                  {suggestVakuutussijoittaminen && (
                    <ProductCard product={flowProductCards[1]} />
                  )}
                  {suggestVarainhoito && (
                    <ProductCard product={flowProductCards[2]} />
                  )}
                  {suggestStrukturoidut && (
                    <ProductCard product={flowProductCards[3]} />
                  )}
                  {suggestYrityksenSijoitukset && (
                    <ProductCard product={flowProductCards[4]} />
                  )}
                </CardsWrapperMobile>
              )}
            </>
          )}
        </Content>
      </HeroContainer>
      {useConfetti ? <HeroConfetti /> : null}
    </Wrapper>
  )
}

SplitHero.propTypes = propTypes

export default SplitHero
