import styled from 'styled-components'

// custom styles for the hubspot form
export const StyledHubspotForm = styled.div(
  ({ theme: t }) => `
      .hs-form-booleancheckbox-display {

    &:first-of-type {
      margin-top: 1em;
    }

    color: ${t.colors.primaryColor};
    cursor: pointer;
    margin-bottom: 1.5em;
    display: block;

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
      span {
        pointer-events: none;
      }
    }

    input {
      &[type='radio'],
      &[type='checkbox'] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      &[type='radio'] + span {
        &:after,
        &:before {
          border-radius: 50%;
          height: 1.5em;
        }

        &:after {
          transform: scale(0.3);
        }
      }

      &[type='checkbox'] + span {
        &:before {
          border-radius: 4px;
          height: 1.5em;
        }

        &:after {
          // prettier-ignore
          transform: matrix(0.20,-0.23,0.23,0.20,0,2); // scaled .3, rotate 45deg or so
          height: 0.9em;
          border-width: 0 0 2px 2px;
          border-color: transparent;
          border-style: solid;
        }
      }
    }

    span:not(.hs-form-required) {
      position: relative;
      padding-left: 2em;
      align-items: center;
      margin-left: 0;

      &:after,
      &:before {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1.5em;
        color: inherit;
        background: transparent;
      }

      &:before {
        content: '';
        border: 1px solid currentColor;
      }

      &:after {
        content: '';
        transition: background 0.125s ease-in, transform 0.1s ease-in;
      }

      a {
        text-decoration: underline;
        color: inherit;
      }
    }

    input:checked + span:after {
      transition-timing-function: ease-out;
    }

    input[type='radio']:checked + span:after {
      background: currentColor;
      transform: scale(0.6);
    }

    input[type='checkbox']:checked + span:after {
      border-color: currentColor;
      // prettier-ignore
      transform: matrix(0.39,-0.45,0.45,0.39,0,2); // scaled .6, rotate 45deg or so
    }

    + p a {
      margin: -1em 0 0 36px;
      display: block;
    }
  }

    input[type='submit'] {
        background: transparent;
        border: 2px solid ${t.colors.primaryColor};
        border-radius: 100px;
        color: ${t.colors.primaryColor};
        font-weight: 500;
        cursor: pointer;
        padding: 0.5em 3em;
    
        &:hover {
            background: ${t.colors.primaryColor};
            color: ${t.colors.white};
        }
        
    }

    input[type='text'], 
    input[type='email'],
    input[type='tel'],
    textarea {
        outline: none;
        border: none;
        border-bottom: 1px solid ${t.colors.primaryColor};
        border-radius: 0;
        background: transparent;
        padding: 0.25em 0;
        width: 100%;
        margin-bottom: 0.75em;
    }

    .hs-error-msg,
    .hs-error-msgs label {
        color: ${t.colors.primaryColor} !important;
        font-weight: 700;
        font-size: 0.875em;
    }

    .hs-error-msgs {
            margin-bottom: 1em;
    }

    .form-columns-2 {
        @media (min-width: ${t.breakpoints.md.min}) {
            display: flex;
            gap: 0.5em;
        }
    }

    div.input {
        margin-right: 0 !important;
    }

    .hs-input {
        width: 100% !important;
        
        &::placeholder {
            color: ${t.colors.primaryColor};
        }
    }

    .submitted-message {
        color: ${t.colors.primaryColor};
    }

`
)
