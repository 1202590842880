import React from 'react'
import styled from 'styled-components'
import Button from '../buttons/button'
import SmallText from '../typography/smallText'
import { Heading } from '../typography/headings'

const NewsletterWrapper = styled.div`
  background-color: rgb(255, 233, 232);
  padding: ${({ theme }) => theme.spacing.medium};
`

const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const CenteredHeader = styled(Heading)`
  margin-top: 40px;
  text-align: center;
  p {
    margin: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
    text-align: left;
  }
`

const CenteredFooter = styled.div`
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
    text-align: left;
  }
`

const SplitGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
  width: ${({ theme }) => theme.width.content};
  @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
    width: 100%;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
  }
`
const LeftColumn = styled.div`
  grid-column: 1;
  @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
    grid-column: 1;
    grid-row: 1;
  }
`

const RightColumn = styled.div`
  grid-column: 2;
  @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
    grid-column: 1;
    grid-row: 2;
  }
`
const TextContainer = styled.div`
  margin: 1em 0;
`

const SubmitWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
  text-align: flex-start;
`

const StyledInput = styled.input(
  ({ theme }) => `
  width: 100%;
  background: ${theme.colors.fieldBackgroundColor};
  border: 1px solid ${theme.colors.greyLight};
  border-radius: 100px;
  padding: ${theme.spacing.small} ${theme.spacing.medium};

  ::placeholder {
    color: ${theme.colors.grey};
  }
`
)

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white} !important;
  border: solid 1px ${({ theme }) => theme.colors.blue};
  padding: 11px 40px;
  @media (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
    padding: 11px 20px;
  }
`

export const CampaignNewsletter = ({ header, formBody, sideBody, footer }) => {
  return (
    <NewsletterWrapper id="newsletter">
      <CenterWrapper>
        <CenteredHeader
          dangerouslySetInnerHTML={{
            __html: header.childMarkdownRemark.html,
          }}
          headingSize={3}
        />
        <SplitGrid>
          <LeftColumn>
            <TextContainer>
              <div
                dangerouslySetInnerHTML={{
                  __html: formBody.childMarkdownRemark.html,
                }}
              ></div>
            </TextContainer>
            <form
              className="lianamailer"
              name="mailingList"
              data-lianamailer="form"
              method="post"
              action="https://uutiskirje.alexandria.fi/account"
            >
              <StyledInput
                name="email"
                type="text"
                placeholder="Sähköpostiosoite*"
                required={true}
              />
              <input type="hidden" name="join" value="1358623" />
              <SubmitWrapper>
                <StyledButton type="submit">Tilaa uutiskirje</StyledButton>
              </SubmitWrapper>
            </form>
          </LeftColumn>
          <RightColumn>
            <TextContainer>
              <div
                dangerouslySetInnerHTML={{
                  __html: sideBody.childMarkdownRemark.html,
                }}
              ></div>
            </TextContainer>
          </RightColumn>
        </SplitGrid>
        <CenteredFooter>
          <TextContainer>
            <SmallText
              dangerouslySetInnerHTML={{
                __html: footer.childMarkdownRemark.html,
              }}
            />
          </TextContainer>
        </CenteredFooter>
      </CenterWrapper>
    </NewsletterWrapper>
  )
}
