import React, { useState } from 'react'
import styled from 'styled-components'
import { lightenBackground } from '../../utils'
import { navigate } from 'gatsby'
// import BackgroundImage from '../backgroundImage'

import { ReactComponent as ArrowIcon } from '../../images/arrow-right.svg'
import { ReactComponent as Rating1 } from '../../images/rating-9.0.svg'
import { ReactComponent as Rating2 } from '../../images/rating-9.3.svg'
import { ReactComponent as Rating3 } from '../../images/rating-8.6.svg'

const Wrapper = styled.div(
  ({ paddingTop }) => `
  display: flex;
  padding: ${paddingTop ? '50px 0 0 0' : '0'};
`
)

const Container = styled.div(
  ({ theme: { colors, breakpoints }, calendarOpen }) => `
  position: relative;
  display: flex;
  width: 100%;
  max-width: 757px;
  height: auto;
  background: ${lightenBackground(colors.pinkLight, 0.4)};
  border-radius: 30px;
  margin: ${calendarOpen ? '50px auto 0 auto' : '0 auto'};
  overflow: hidden;
  justify-content: center;

  @media (max-width: ${breakpoints.md.max}) {
    flex-direction: column;
  }
`
)

/*
const ImageWrap = styled.div(
  ({ theme: { breakpoints } }) => `
  display: flex;
  width: 332px;
  height: 100%;
  object-fit: cover;

  img {
    object-fit: cover;
    width: 332px;
    height: 100%;
  }

  @media (max-width: ${breakpoints.md.max}) {
    height: 230px;
    width: 100%;

    img {
      height: 230px;
      width: 100%;
    }
  }
`
)
*/

const Content = styled.div(
  ({ theme: { breakpoints } }) => `
  display: flex;
  width: 697px;
  flex-direction: column;
  padding: 30px;
  margin 0 30px;
  gap: 25px;

  @media (max-width: ${breakpoints.md.max}) {
    width: 100%;
    padding: 20px;
    margin: 0 auto;
  }

  h3 {
    font-size: 38px;
    line-height: 42px;
    margin: 0;

    @media (max-width: ${breakpoints.md.max}) {
      font-size: 28px;
      line-height: 33px;
    }
  }

  h4 {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }
  
  p {
    font-size: 20px;
    line-height: 28px;
    margin: 0;

    @media (max-width: ${breakpoints.md.max}) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  a {
    display: flex;
    width: 165px;
    height: 45px;
    border-width: 2px;
    background: transparent;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
`
)

const Step = styled.div(
  ({ theme: { breakpoints } }) => `
  display: flex;
  width: 100%;
  gap: 30px;
  align-items: center;
  word-break: break-word;

  span {
    width: 25px;
    height: 25px;
  }

  @media (max-width: ${breakpoints.md.max}) {
    justify-content: space-between;
    gap: 10px;
  }
`
)

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme: { colors } }) => colors.primaryColor};
  margin: 0.5em 0;
`

const Ratings = styled.div(
  ({ theme: { breakpoints } }) => `
  display: flex;
  margin: 0;
  gap: 20px;
      justify-content: space-evenly;

  @media (max-width: ${breakpoints.md.max}) {
    margin: 0;
    gap: 10px;
    justify-content: space-evenly;
  }
`
)

const Rating = styled.div(
  ({ theme: { breakpoints } }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 20px;
  gap: 10px;
  word-break: break-word;

  @media (max-width: ${breakpoints.md.max}) {
    padding: 0;
    font-size: 14px;
  }
`
)

const CtaButton = styled.button(
  ({ theme: { colors } }) => `
  display: flex;
  height: 45px;
  border: 2px solid ${colors.primaryColor};
  color: ${colors.primaryColor};
  background: transparent;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 30px;
  cursor: pointer;
  padding: 0 20px;

  &:hover {
    background: ${colors.primaryColor};
    color: ${colors.white};
  }
`
)

const CtaContainer = styled.div`
  display: flex;
  gap: 40px;
  margin-top: 20px;

  @media (max-width: ${props => props.theme.breakpoints.xs.max}) {
    flex-direction: column;
  }
`

const CtaButtonElement = styled.div`
  display: flex;
  gap: 1em;
  flex-direction: column;
  align-items: flex-start;
`

const CalendarContainerDesktop = styled.div`
  display: flex;
  max-width: 697px;
  width: 697px;
  height: 670px;
  border-radius: 30px;
  padding: 0 37px 0 0;
  background: ${({ theme: { colors } }) => colors.white};
  align-self: center;
  overflow: hidden;

  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints.md.max}) {
    width: calc(100% + 36px);
    margin: 0 -18px;
    padding: 0;
    overflow: hidden;
  }
`

const ReservationContainer = ({ calendarOpen, paddingTop }) => {
  const [showCalendar, setShowCalendar] = useState(calendarOpen)
  return (
    <Wrapper paddingTop={paddingTop}>
      <Container calendarOpen={calendarOpen}>
        {/*
        <ImageWrap article={!image.gatsbyImageData}>
          {image.gatsbyImageData ? (
            <BackgroundImage image={image.gatsbyImageData} alt={''} />
          ) : (
            <img src={image} alt={''} />
          )}
        </ImageWrap>
        */}
        <Content>
          {showCalendar ? (
            <CalendarContainerDesktop>
              <iframe
                src="https://oma.alexandria.fi/ajanvaraus"
                name="calendar"
                frameborder="0"
                height="100%"
                width="100%"
              ></iframe>
            </CalendarContainerDesktop>
          ) : (
            <>
              <h3>Aloita sijoittaminen helposti</h3>
              <p>
                Sijoittamisen aloittaminen Alexandriassa on helppoa. Voit
                aloittaa omatoimisesti verkkopalvelussamme tai
                sijoitusneuvojamme avulla. OmaAlexandriassa pääset heti alkuun,
                kun taas maksuttomassa ensitapaamisessa käymme läpi
                taloudellisia tavoitteistasi ja sijoitusratkaisuja, jotka vievät
                sinua unelmiasi kohti.
              </p>
              <CtaContainer>
                <CtaButtonElement>
                  <h4>
                    <em>Itsenäisesti</em>
                    <br /> OmaAlexandriassa
                  </h4>
                  <CtaButton
                    onClick={() => navigate('https://oma.alexandria.fi')}
                  >
                    Kokeile verkkopalvelua
                  </CtaButton>
                </CtaButtonElement>
                <CtaButtonElement>
                  <h4>
                    <em>Yhdessä</em>
                    <br /> sijoitusneuvojan kanssa
                  </h4>
                  <CtaButton onClick={() => setShowCalendar(!showCalendar)}>
                    Varaa aika ensitapaamiseen
                  </CtaButton>
                </CtaButtonElement>
              </CtaContainer>
              <Line />
            </>
          )}
          <Ratings>
            <Rating>
              <span>
                <Rating1 />
              </span>
              <span>
                Kokonaisarvosana <br /> tapaamiselle
              </span>
            </Rating>
            <Rating>
              <span>
                <Rating2 />
              </span>
              <span>
                Sijoitusneuvojan <br /> asiantuntevuus
              </span>
            </Rating>
            <Rating>
              <span>
                <Rating3 />
              </span>
              <span>
                Suosittelisi <br /> palveluitamme
              </span>
            </Rating>
          </Ratings>
        </Content>
      </Container>
    </Wrapper>
  )
}

export default ReservationContainer
