import React, { useEffect } from 'react'
import styled from 'styled-components'
import { StyledHubspotForm } from './styles'
import { lightenBackground } from '../../utils'
import { Heading } from '../typography/headings'

const HUBSPOT_SCRIPT_SRC = 'https://js-eu1.hsforms.net/forms/v2.js'
const HUBSPOT_PORTAL_ID = '145538753'

const HubspotFormWrapper = styled.div(
  ({ theme: t }) => `
    width: 100%;
    color: ${t.colors.white};
    background: ${t.colors.orange};
    padding: 108px 183px 91px 145px;
    margin-bottom: 0 !important;
  
    @media (max-width: ${t.breakpoints.md.max}) {
      padding: 62px 25px 58px 25px;
    }
  `
)

const HubspotFormContent = styled.div(
  ({ theme: t }) => `
    display: flex;
    max-width: ${t.width.max};
    margin: 0 auto;
    gap: 130px;
    justify-content: center;
  
    @media (max-width: ${t.breakpoints.md.max}) {
      flex-direction: column;
      gap: 40px;
    }
  `
)

const TextContent = styled.div(
  ({ theme: t }) => `
    display: flex;
    flex-direction: column;
    max-width: 401px;
    gap: 20px;
    color: ${t.colors.primaryColor};
  
    @media (max-width: ${t.breakpoints.md.max}) {
      gap: 10px;
    }
  `
)

const Title = styled(Heading)(
  ({ theme: t }) => `
    font-size: 38px;
    line-height: 42px;
    margin-bottom: 40px;
  
    @media (max-width: ${t.breakpoints.md.max}) {
      margin-bottom: 0;
    }
  `
)

const Description = styled.p`
  font-size: 20px;
  line-height: 28px;
  margin: 0;
`

const HubspotForm = ({ config }) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = HUBSPOT_SCRIPT_SRC
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: HUBSPOT_PORTAL_ID,
          formId: config.formId,
          target: '#hubspotFormSlot',
        })
      }
    })
  }, [])

  return (
    <HubspotFormWrapper id="hubspot-form-anchor">
      <HubspotFormContent>
        <TextContent>
          <Title>{config.title}</Title>
          <Description>{config.body}</Description>
        </TextContent>
        <StyledHubspotForm>
          <div id="hubspotFormSlot" />
        </StyledHubspotForm>
      </HubspotFormContent>
    </HubspotFormWrapper>
  )
}

export { HubspotForm }
